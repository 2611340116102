'use client';

import { useQuery } from '@tanstack/react-query';
import { ImageProps } from 'next/image';
import { Skeleton, SxProps } from '@mui/material';
import { PreserveAspectRatioImage } from '@qb/frontend/components/PreserveAspectRatioImage';
import noBrandBox from '@qb/frontend/icons/no-brand-box.png';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { spsQueries } from '@qb/httpRequest/spsQueries';
import { getConfigFromTenantAlias, TenantAlias } from '@/shared/tenants-alias-map';
import { isNotEmpty } from '@/shared/types/util/TypeGuards';
type ImageWithFallbackProps = Omit<ImageProps, 'src'> & {
  tenantAlias: TenantAlias;
  src: ImageProps['src'] | undefined;
  sx?: SxProps;
  bgColorFill?: string;
};
export const ImageWithFallback = ({
  src,
  tenantAlias,
  bgColorFill,
  width,
  height,
  ...restImageProps
}: ImageWithFallbackProps) => {
  if (!!src) {
    return <PreserveAspectRatioImage src={src} width={width} height={height} bgColorFill={bgColorFill} {...restImageProps} />;
  }
  return <ImageFallback tenantAlias={tenantAlias} width={width} height={height} bgColorFill={bgColorFill} {...restImageProps} data-sentry-element="ImageFallback" data-sentry-component="ImageWithFallback" data-sentry-source-file="ImageWithFallback.tsx" />;
};
const ImageFallback = ({
  tenantAlias,
  bgColorFill,
  width,
  height,
  ...restImageProps
}: Omit<ImageWithFallbackProps, 'src'>) => {
  const host = getHostFromWindow();
  const appID = getConfigFromTenantAlias(tenantAlias)?.appID;
  const isQueryEnabled = isNotEmpty(tenantAlias) && isNotEmpty(appID);
  const {
    data,
    isLoading,
    isSuccess
  } = useQuery({
    ...spsQueries.app.get(host, tenantAlias, appID),
    enabled: isNotEmpty(tenantAlias) && isNotEmpty(appID)
  });
  if (isQueryEnabled && isLoading) {
    return <Skeleton variant="rounded" width={width} height={height} sx={{
      flexShrink: 0
    }} />;
  }
  if (isSuccess && !!data?.missingImageTarget) {
    return <PreserveAspectRatioImage src={data.missingImageTarget} width={width} height={height} bgColorFill={bgColorFill} {...restImageProps} />;
  }
  return <PreserveAspectRatioImage src={noBrandBox.src} width={width} height={height} bgColorFill={bgColorFill} {...restImageProps} data-sentry-element="PreserveAspectRatioImage" data-sentry-component="ImageFallback" data-sentry-source-file="ImageWithFallback.tsx" />;
};